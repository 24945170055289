<template>
  <div>
    <b-row>
      <b-col v-if="bus_seating.length > 0" md="12">
        <div class="w-100 text-center">
          <strong>{{bus.name + " " + broadcast_date}}</strong> 
        </div>
        <div class="w-100 ">
          <div class="row  justify-content-center aling-items-center">
            <div class="col-auto p-3 text-center">
              <div class="bg-bus p-3">
                <table class=" block-seats">
                  <tbody>
                    <tr>
                      <td><div :title="bus_seating[3].description" @click="ViewSeating(bus_seating[3].id_seating)" :class="bus_seating[3].background">{{bus_seating[3].code}}</div></td>
                      <td><div :title="bus_seating[7].description" @click="ViewSeating(bus_seating[7].id_seating)" :class="bus_seating[7].background">{{bus_seating[7].code}}</div></td>
                      <td><div :title="bus_seating[11].description" @click="ViewSeating(bus_seating[11].id_seating)" :class="bus_seating[11].background">{{bus_seating[11].code}}</div></td>
                      <td><div :title="bus_seating[15].description" @click="ViewSeating(bus_seating[15].id_seating)" :class="bus_seating[15].background">{{bus_seating[15].code}}</div></td>
                      <td><div :title="bus_seating[19].description" @click="ViewSeating(bus_seating[19].id_seating)" :class="bus_seating[19].background">{{bus_seating[19].code}}</div></td>
                      
                    </tr>
                    <tr>
                      <td><div :title="bus_seating[2].description" @click="ViewSeating(bus_seating[2].id_seating)" :class="bus_seating[2].background">{{bus_seating[2].code}}</div></td>
                      <td><div :title="bus_seating[6].description" @click="ViewSeating(bus_seating[6].id_seating)" :class="bus_seating[6].background">{{bus_seating[6].code}}</div></td>
                      <td><div :title="bus_seating[10].description" @click="ViewSeating(bus_seating[10].id_seating)" :class="bus_seating[10].background">{{bus_seating[10].code}}</div></td>
                      <td><div :title="bus_seating[14].description" @click="ViewSeating(bus_seating[14].id_seating)" :class="bus_seating[14].background">{{bus_seating[14].code}}</div></td>
                      <td><div :title="bus_seating[18].description" @click="ViewSeating(bus_seating[18].id_seating)" :class="bus_seating[18].background">{{bus_seating[18].code}}</div></td>
                      
                    </tr>
                    <tr>
                      <td><br></td>
                      <td><br></td>
                      <td><br></td>
                      <td><br></td>
                      <td><br></td>
                      <td><br></td>
                    </tr>
                    <tr>
                      <td><div :title="bus_seating[1].description" @click="ViewSeating(bus_seating[1].id_seating)" :class="bus_seating[1].background">{{bus_seating[1].code}}</div></td>
                      <td><div :title="bus_seating[5].description" @click="ViewSeating(bus_seating[5].id_seating)" :class="bus_seating[5].background">{{bus_seating[5].code}}</div></td>
                      <td><div :title="bus_seating[9].description" @click="ViewSeating(bus_seating[9].id_seating)" :class="bus_seating[9].background">{{bus_seating[9].code}}</div></td>
                      <td><div :title="bus_seating[13].description" @click="ViewSeating(bus_seating[13].id_seating)" :class="bus_seating[13].background">{{bus_seating[13].code}}</div></td>
                      <td><div :title="bus_seating[17].description" @click="ViewSeating(bus_seating[17].id_seating)" :class="bus_seating[17].background">{{bus_seating[17].code}}</div></td>
                      
                    </tr>
                    <tr>
                      <td><div :title="bus_seating[0].description" @click="ViewSeating(bus_seating[0].id_seating)" :class="bus_seating[0].background">{{bus_seating[0].code}}</div></td>
                      <td><div :title="bus_seating[4].description" @click="ViewSeating(bus_seating[4].id_seating)" :class="bus_seating[4].background">{{bus_seating[4].code}}</div></td>
                      <td><div :title="bus_seating[8].description" @click="ViewSeating(bus_seating[8].id_seating)" :class="bus_seating[8].background">{{bus_seating[8].code}}</div></td>
                      <td><div :title="bus_seating[12].description" @click="ViewSeating(bus_seating[12].id_seating)" :class="bus_seating[12].background">{{bus_seating[12].code}}</div></td>
                      <td><div :title="bus_seating[16].description" @click="ViewSeating(bus_seating[16].id_seating)" :class="bus_seating[16].background">{{bus_seating[16].code}}</div></td>
                      
                    </tr>
                  
                  </tbody>
                </table>
              </div>
            </div>  
          </div>
        </div>
      </b-col>
    </b-row>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>
<style scoped lang="scss">
.bg-bus{
    background-image: url('~@/assets/img/buses/bus30.png');
    background-repeat: no-repeat;
    background-position: center center;
    -o-background-size: 100% 100%, auto;
    -moz-background-size: 100% 100%, auto;
    -webkit-background-size: 100% 100%, auto;
    background-size: 100% 100%, auto;
    width: 400px;
    padding: 10px;
}
.block-seats{
  width: 300px !important;
  margin: auto;
}
.disponible {
    background-image: url('~@/assets/img/asientos/Disponible.png');
    background-repeat: no-repeat;
    position: relative;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 15px;
    color: #000000;
    cursor: pointer;
    margin: auto;
}

.reservado {
    background-image: url('~@/assets/img/asientos/Reservado.png');
    background-repeat: no-repeat;
    position: relative;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 15px;
    color: #000000;
    cursor: pointer;
    margin: auto;
}

.ocupado {
    background-image: url('~@/assets/img/asientos/Ocupado.png');
   background-repeat: no-repeat;
    position: relative;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 15px;
    color: #000000;
    cursor: pointer;
    margin: auto;
}
table tr td{
  width: 40px;
}
</style>
<script>

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import LoadingComponent from './../../pages/Loading'
export default {
  name: "Bus",
  components:{
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'ControlSeats',
      role: '',
      bus: {
          id_bus:'',
          name:'',
          license_plate:'',
          driver:'',
          seating:'',
      },
      route:{
        id_route:'',
        name:''
      },
      tour:{
        id_tour:'',
      },
      // bus_seating:[],
      broadcast_date: '',
      
      validate: false,
    };
  },
  mounted() {
    EventBus.$on('BusInformation', (data) => {
      console.log(data);
      this.role = data.role;
      this.broadcast_date = data.broadcast_date;
      this.tour.id_tour = data.id_tour;
    });
  },
  methods: {
    ViewSeating,
    Refresh,
   ...mapActions('ControlSeats',['mLoadAddControlSeatDetail']),
  },

  computed: {
    ...mapState('ControlSeats',["bus_seating"]),
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function Refresh() {
  EventBus.$emit('SearchAvailability');
}

function ViewSeating(id_seating) {

  let data = {
    id_tour : this.tour.id_tour,
    id_seating: id_seating,
    broadcast_date : this.broadcast_date,
  }

  let me = this;
  let url = me.url_base + "control-seats/validate-seating";
  axios({
    method: "POST",
    url:url,
    data:data,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      if (response.data.result.control_seat.state == 0) {
        let data = {
          id_seating:response.data.result.control_seat.id_seating,
          id_tour:response.data.result.tour.id_tour,
          date:response.data.result.date,
          seating_code:response.data.result.control_seat.code,
          bus_name:response.data.result.bus.name+" | "+response.data.result.tour.departure_time,
          route_name:response.data.result.tour.route_name,
          price_pen:response.data.result.control_seat.price_pen,
          price_usd:response.data.result.control_seat.price_usd,
          state:1,
        };
        me.mLoadAddControlSeatDetail(data)
        
      }else{
        me.$notify({ group: 'alert', title: 'Sistema', text:'El asiento seleccionado no se encuentra disponible ' , 
        type: 'danger'});
      }
      
    }
  })
  .catch((error) => {
  });

}


</script>
