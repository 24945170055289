<template>
  <div>
    <CRow>
      <CCol col>
    
        <CCard>
          <CCardHeader>
            <strong> Modulo Control Asientos - Nuevo</strong>
          </CCardHeader>
        <CCardBody>
          
          <b-row class="bg-light py-5">
            <b-col class="border-left" md="6">
              <b-form id="Form" @submit.prevent="SearchAvailability">
              <b-row>

                <b-col md="5">
                  <b-form-group label="Ruta:">
                    <b-form-select @change="ListTour"  size="sm" v-model="control_seats.id_route" :options="routes"></b-form-select>
                    <small v-if="errors.id_bus"  class="form-text text-danger" >Seleccione una ruta</small>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Tour:">
                    <b-form-select size="sm" @change="SearchAvailability" v-model="control_seats.id_tour"  :options="tours"></b-form-select>
                    <small v-if="errors.id_tour"  class="form-text text-danger" >Seleccione una unidad</small>
                  </b-form-group>
                </b-col>


                <b-col md="3">
                  <b-form-group label="Fecha:">
                    <b-form-input @change="SearchAvailability" size="sm" class="text-center" type="date" v-model="control_seats.broadcast_date"></b-form-input>
                    <small v-if="errors.id_bus"  class="form-text text-danger" >Seleccione una fecha</small>
                  </b-form-group>
                </b-col>
              </b-row>
              </b-form>

              <Bus/>

            </b-col>
            <b-col md="6">
              <b-form id="Form" @submit.prevent="Validate">
                <div class="row" v-if="this.control_seats_detail.length == 0">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 text-center py-1">
                          <h5>Mapa de Asientos</h5>
                        </div>
                        <div class="col-md-4 text-center">
                          <div class="d-flex"><img  src="~@/assets/img/asientos/bg-disponible.png" alt=""> Disponible</div>
                        </div>
                        <div class="col-md-4 text-center">
                          <div class="d-flex"><img  src="~@/assets/img/asientos/bg-reservado.png" alt=""> Reservado</div>
                        </div>
                        <div class="col-md-4 text-center">
                          <div class="d-flex"><img  src="~@/assets/img/asientos/bg-ocupado.png" alt=""> No Disponible</div>
                        </div>
                      </div>
                    </div>
                  </div>
                      
                </div>
                <div class="row" v-if="this.control_seats_detail.length > 0">
                  <div class="col-md-12 pt-3 pb-3">
                    <div class="card">
                      <div class="card-body">
                        <b-row>
                          <div class="col-md-12 text-center py-1">
                            <h5>Datos de la Reserva</h5>
                          </div>
                          <b-col md="9">
                            <b-form-group>
                              <label>Cliente: <b-badge @click="modalClients" variant="info" class="link">Nuevo</b-badge></label>
                              <v-select placeholder="Seleccione un cliente" class="w-100" :filterable="false" label="full_name" v-model="client" @search="SearchClients" :options="clients"></v-select>
                              <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                            </b-form-group>
                          </b-col>  
                          <b-col md="3">
                            <b-form-group label="Moneda:">
                              <b-form-select :options="coin" v-model="control_seats.coin"></b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <h5 class="card-title">{{ route.name }}</h5>
                        <p class="card-text">{{ route.description }}</p>
                        <hr>
                        <h5 class="card-title">SALIDA</h5>
                        <div class="d-flex justify-content-between">
                          <p class="card-text">{{tour.place_departure}}</p>
                          <strong>{{tour.departure_time}}</strong>
                        </div>
                        <hr>
                        <div class="d-flex justify-content-between">
                          <div class="">
                            <strong>N° de Asiento</strong>
                          </div>
                          <div class="">
                            <strong v-for="(item, it) in control_seats_detail" :key="it">{{ item.seating_code }},  </strong>
                          </div>
                        </div>
                        <hr>
                        <h5 class="card-title">Detalle del Precio</h5>
                        <div class="d-flex justify-content-between">
                          <p class="card-text">Total</p>
                          <strong v-if="control_seats.coin == 'PEN'">S/ {{total.total_pen}}</strong>
                          <strong v-if="control_seats.coin == 'USD'">$ {{total.total_usd}}</strong>
                        </div>
                        
                        <hr>

                        <button type="submit" class="btn btn-primary">
                          RESERVAR
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </b-form>
            </b-col>
          </b-row>
            
        </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients/>
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
import Bus from './buses/Bus'
import Bus20 from './buses/Bus20'


export default {
  name: "UsuarioAdd",
  components:{
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
      ModalClients,
      vSelect,
      Bus20,
      Bus,
  },
  data() {
    return {
      isLoading: false,
      module: 'ControlSeats',
      role: 2,
      control_seats: {
          id_control_seats:'',
          id_bus:'',
          id_route:'',
          id_tour:'',
          id_client:'',
          code:'',
          broadcast_date:moment(new Date()).local().format("YYYY-MM-DD"),
          coin:'PEN',
          type_invoice:'03',
          payment_method:'EFECTIVO',
          charge_code:'',
          total:'0.00',
          observation:'',
          registration_date:'',
          state:1,
          control_seats_detail:[],
      },
      tours:[{value:'',text:'-- Seleccione una tour --'}],
      buses:[],
      routes: [{value:'',text:'-- Seleccione una ruta --'}],
      payment_cash:[],
      coin:[
        {value:'PEN', text:'Soles'},
        {value:'USD', text:'Dólares'},
      ],
      route:{},
      tour:{},
      name_date:'',
      bus: {
        seating:'',
      },
      clients:[],
      client:{id:1, full_name: 'CLIENTES VARIOS - 00000000'},
      seat:{
        id_control_seats_detail: '',
        id_control_seats: '',
        id_seating: '',
        id_route: '',
        date: '',
        departure_time:'',
        seating_code: '',
        price_pen: '',
        price_usd: '',
        state: '',
      },
       type_invoice:[
        {value: "NV", text : "Nota de Venta"},
        {value: "01", text : "Factura"},
        {value: "03", text : "Boleta de Venta"},
       ],
      payment_method: [
          {value :"001", text :'DEPÓSITO EN CUENTA'},
          {value :"003", text :'TRANSFERENCIA DE FONDOS'},
          {value :"005", text :'TARJETA DE DÉBITO'},
          {value :"006", text :'TARJETA DE CRÉDITO'},
          {value :"008", text :'EFECTIVO'},
          {value :"000", text :'PAGO POR WEB'},
      ],
      errors: {
        id_bus: false,
        name: false,
        description: false,
        departure_time: false,
        price: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListRoute();
  },
  methods: {
    ListTour,
    ListRoute,
    SearchAvailability,

    modalClients,
    SearchClients,

    AddControlSeat,
    Validate,

    DataPrint,
    Print,

    ...mapActions('ControlSeats',['mLoadResetDetail']),
    ...mapActions('ControlSeats',['mLoadBusSeating']),
  },

  computed: {
    
    ...mapState('ControlSeats',["control_seats_detail","total"]),
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};


function SearchClients(search, loading) {
  
    let me = this;
    let url = this.url_base + "client/search/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
        headers: { token: me.token, module: me.module, role: me.role},
      }).then(function (response) {
            me.clients = response.data;
            loading(false);
      })
    }
}

function modalClients() {
  EventBus.$emit('ModalClientsShow');
}

function ListTour() {
  let me = this;
  if (this.control_seats.id_route.length == 0) {
    me.tours = [{value:'',text:'-- Seleccione una ruta --'}];
    me.control_seats.id_tour = '';
    return false;
  }
  let url = me.url_base + "tour/get-tour/"+this.control_seats.id_route;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    me.tours = [{value:'',text:'-- Seleccione una tour --'}];
    if (response.data.status == 200) {
      for (let index = 0; index < response.data.result.length; index++) {
        const element = response.data.result[index];
        me.tours.push({value:element.id_tour, text: element.bus_name+" | "+element.departure_time });
      }
    }
  })
  .catch((error) => {
  });
}

function ListRoute() {
  let me = this;
  let url = me.url_base + "route/select-active";
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    me.routes = [{value:'',text:'-- Seleccione una ruta --'}];
    if (response.data.status == 200) {
      for (let index = 0; index < response.data.result.length; index++) {
        const element = response.data.result[index];
        me.routes.push({value:element.id_route, text: element.name });
      }
    }
  })
  .catch((error) => {
  });
}

function SearchAvailability() {

  if (this.control_seats.id_tour.length == 0) {
    return false;
  }
  if (this.control_seats.broadcast_date.length == 0) {
    return false;
  }

  this.mLoadResetDetail();
  
  let me = this;
  let url = me.url_base + "control-seats/search-availability";
  let data = me.control_seats;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        let data = {
          broadcast_date :response.data.result.broadcast_date,
          role:2,
          id_tour:response.data.result.tour.id_tour,
        };
        EventBus.$emit('BusInformation',data);
        me.route = response.data.result.route;
        me.bus = response.data.result.bus;
        me.tour = response.data.result.tour;
        me.name_date = response.data.result.name_date;
        me.mLoadBusSeating(response.data.result.bus_seating);
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}


function AddControlSeat() {
  let me = this;
  let url = me.url_base + "control-seats/add";
  me.control_seats.id_client = me.client.id;
  me.control_seats.id_user = me.user.id_user;
  me.control_seats.total = me.control_seats.coin == "PEN" ? me.total.total_pen : me.total.total_usd;
  me.control_seats.control_seats_detail = me.control_seats_detail;

  let data = me.control_seats;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.client = {id: 1, full_name: 'CLIENTES VARIOS - 00000000'};
        me.mLoadResetDetail();
        me.SearchAvailability();
        me.DataPrint(response.data.result.id_control_seats);
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  let total_final = 0;
  this.errors.id_client = this.client == null ? true : false;
  this.errors.coin = this.control_seats.coin.length == 0 ? true : false;
  this.errors.control_seats_detail = this.control_seats_detail.length == 0 ? true : false;
  if (this.control_seats.coin == "PEN") {
    this.errors.total = parseFloat(this.total.total_pen) == 0 ? true : false;
    total_final = this.total.total_pen;
  }else{
    this.errors.total = parseFloat(this.total.total_usd) == 0 ? true : false;
    total_final = this.total.total_usd;
  }
  
  if (this.errors.id_client) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.coin) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.control_seats_detail) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar la reserva ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddControlSeat();
    }
  });
}

function DataPrint(id_control_seats) {
  let me = this;
  let url = me.url_base + "control-seats/data-print/"+id_control_seats;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role, },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.Print(response.data.result);
    } 
  })

  
}

function Print(info) {
  let url = 'http://localhost/print/consumirapi.php';
  var data = new FormData(); 
  data.append("info",JSON.stringify(info)); 

  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
    });
}


</script>
